import React, { useState, useEffect, useContext } from 'react'
import './style.scss'
import Survey from '../Survey'
import OrderConfirmation from '../OrderConfirmation'
import FBTrack from '../FBTrack'
import ShareASalePixel from '../ShareASaleTrack'
import roktTrack from '../RoktTrack'
import axios from 'axios'
import DynamicButton from '../DynamicButton'
import CartContext from "../../context/cartContext"
import WebpImage from '../WebpImage'


const Thanks = () => {
  const [email, setEmail] = useState('')
  const [firstName,setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [zip, setZip] = useState('')
  const [userData, setUserData] = useState({})
  const [orderValue, setOrderValue] = useState('')
  const [orderNumber, setOrderNumber] = useState('')
  const [sku, setSku] = useState('')
  const [orderType, setOrderType] =  useState("contacts")
  const [utmCampaign, setUtmCampaign] = useState("")
  const [ccOrder, setCcOrder] =  useState(false)
  const [phone,setPhone] = useState("")
  const [ipAddress, setIpAddress] = useState("127.0.0.0")
  const cartContext = useContext(CartContext)

  const advancedMatching = {
    em: email,
    fn: firstName,
    ln: lastName,
    zp: zip
  }

  useEffect(() => {
    const encodedData = getParameterByName('data');
    if (typeof window !== 'undefined') {
      if( typeof window.ampt  !== 'undefined' ){
        window.ampt.track('PurchaseComplete');
        window.ampt.track('InitiateCheckoutAndPurchase');
        window.safariAmpt.track('PurchaseComplete');
        window.safariAmpt.track('InitiateCheckoutAndPurchase');
      }

      axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIpAddress(response.data.ip)
      }, error => {
        console.log(error);
      });

      if (encodedData !== undefined) {
        decodeJSON(encodedData);
      }
    }

    cartContext.clearCart()
  },[])

  useEffect(()=>{
    if (typeof window !== 'undefined'  && orderNumber !== '') {
      if (typeof window.fbq !== 'undefined') {
        window.fbq('init', process.env.HUBBLE_FACEBOOK_PIXEL, advancedMatching)
        if (orderType === "contacts"){
          FBTrack.fbTrackSubscribe(orderNumber, 1.00, "Hubble Contacts")
        }
        if (orderType === "frames" || orderType === "Frames"){
          window.fbq('init', process.env.HUBBLE_FACEBOOK_PIXEL, advancedMatching)
          window.fbq('init', "324205144886205", advancedMatching)
          FBTrack.fbTrackSubscribe(orderNumber, orderValue, "Frames")
          FBTrack.fbTrack('trackCustom', 'FramesPurchaseComplete')

          axios.post(`${process.env.GATSBY_API_URL}/api/facebook_postback`,{
            email: email,
            fn: firstName,
            ln: lastName,
            order_id: orderNumber,
            pixel_id: ['177398499339719'],
            event_name: "FramesPurchaseComplete",
            url: typeof window !== "undefined" ? window?.location?.href : null,
            user_agent: navigator.userAgent,
            phone: phone,
            client_ip_address: ipAddress,
            price: orderValue,
          },
          { headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Credentials':true,
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          }}
          )
        }
      }

      if (typeof window.pintrk !== 'undefined') {
        window.pintrk('track', 'checkout', {
          value: 1,
          order_quantity: 1,
          currency: 'USD',
          order_id: orderNumber
        });
      }

      axios.post(`${process.env.GATSBY_API_URL}/api/facebook_postback`,{
        email: email,
        fn: firstName,
        ln: lastName,
        order_id: orderNumber,
        pixel_id: ['177398499339719'],
        event_name: "Subscribe",
        url: typeof window !== "undefined" ? window?.location?.href : null,
        user_agent: navigator.userAgent,
        phone: phone,
        client_ip_address: ipAddress,
        price: 1.00,
      },
      { headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Credentials':true,
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }}
      )
      if (typeof window.ttq !== 'undefined') {
        window.ttq.instance('C7BJSBIGCKHAS5EFKLK0').track('Purchase')
      }

      axios.post(`${process.env.GATSBY_API_URL}/api/tiktok_postback`,{
        pixel: 'C7BJSBIGCKHAS5EFKLK0',
        event: 'Purchase',
        order_id: orderNumber,
        phone: phone,
        email: email,
      },
      { headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Credentials':true,
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }}
      )
    }
  }, [orderNumber])

  useEffect(()=>{
    if (typeof window !== 'undefined' && Object.keys(userData).length !== 0 && (userData||{}).url) {
      const clickId = getClickID(userData)
      if (clickId !== undefined) {
          axios.post(`https://hubble-api-v1.herokuapp.com/api/amobee_postback`,{order_number: orderNumber, click_id: clickId},
            { headers: {
              'Access-Control-Allow-Origin' : '*',
              'Access-Control-Allow-Credentials':true,
              'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }}
        )
      // if route contains EMP_CPM
      if(typeof window !== "undefined" && window?.location?.href.includes("EMO_CPM")){
        axios.post(`https://hubble-api-v1.herokuapp.com/api/amobee_cpm_postback`,{order_number: orderNumber, click_id: clickId},
        { headers: {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Credentials':true,
          'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }}
        )
      }
      }
    }
  }, [userData])

  roktTrack(userData);

  const getParameterByName = (name) => {
    const url =  typeof window !== "undefined" ? window?.location?.href : null;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
    const results = regex.exec(url);
    if (!results) {
      return;
    }
    if (!results[2]) {
      return
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const decodeJSON = (encodedString) => {
    const decodedObject = decodeURIComponent(atob(_convertFriendlyBase64toBase64(encodedString)));
    const data = JSON.parse(decodedObject)
    setEmail(data["email"])
    setFirstName(data['name'].split(' ')[0])
    setLastName(data['name'].split(' ')[1])
    setZip(data['zip'])
    setOrderNumber(data['order_number'] || data['order_id'])
    setUserData(data)
    setOrderValue(data['price'])
    setUtmCampaign(data['utm_campaign'])
    setSku(data['sku'])
    if (data['url'] === "frames") {
      setOrderType("Frames")
    }
    setPhone(data['phone'])
  }

  const getClickID = () => {
    const split_url = userData.url.split("&")
    let clickId = ""
    split_url.forEach(function(a){
        if (typeof(a) === 'string' && a.indexOf('clickid')>-1) {
          clickId = a.split("=")[1].split("#")[0]
        }
    });
    return clickId
  }

  const _convertFriendlyBase64toBase64 = (str) => {
    if (str){
      str = (str + '===').slice(0, str.length + (str.length % 4));
      return str.replace(/-/g, '+').replace(/_/g, '/');
    }
  }


  return (
    <div className="thanks-section container text-center">
      <div className="row thanks-row">
        <div className="col-md-6 survey-wrapper">
          <Survey order_id={orderNumber} customerEmail={email} />
        </div>
        <div className="horizontal-separator-2-col"></div>
        <div className="col-md-5 order-details-wrapper">
          <OrderConfirmation userData={userData} />
        </div>
      </div>

      {orderType === "contacts" &&
        <div className="glasses-hero row fancy-rounded-corners">
          <div className='hubble-dots-wrapper'>
            <WebpImage className="hubble-dots" fileName='white-dots.svg' />
          </div>
          <div className="image-wrapper">
            <div className="hidden-mobile">
              <WebpImage fileName='homepage-couple.png'/>
            </div>
            <div className="hidden-desktop">
              <WebpImage fileName='homepage-couple-mobile.png'/>
            </div>
          </div>
          <div className="text-wrapper">
            <h2 className="glasses-text"> Frames by Hubble<br />
                                          Just $49.99
            </h2>
            <DynamicButton
              href={`/glasses`}
              copy="Browse Styles"
              handleClick={(e) => {
                e.preventDefault();
                FBTrack.fbTrack('trackCustom', `Hubble Glasses Link Click`)
                // gtagTrack('click', 'link', `Hubble Glasses link click`);
                window.location = `/glasses`;
              }}
              textColor="darkCyanLimeGreen"
              bgColor="white"
              size="m"
              borderColor="darkCyanLimeGreen" />
          </div>
        </div>
      }



      {orderType !== "contacts" &&
        <div className="glasses-hero row fancy-rounded-corners banner">
          <div className='hubble-dots-wrapper'>
            <WebpImage
              className="hubble-dots"
              fileName="white-dots.svg"
            />
          </div>
          <div className="hidden-mobile banner-img">
            <WebpImage
              fileName="moreContactsBG.jpg"
            />
          </div>
          <div className="hidden-desktop banner-img">
            <WebpImage
              fileName="moreContactsBGMobile.jpg"
            />
          </div>
          <div className="text-wrapper-banner ">
            <h2 className="glasses-text"> Need more contacts?</h2>
            <div className="glasses-text-wrapper-banner">
              <p className="glasses-text">30 Hubble contacts for $1</p>
            </div>
            <DynamicButton
              href={`https://intake.hubblecontacts.com/get-lenses${utmCampaign}`}
              copy="Get $1 box"
              handleClick={e => {
                //
              }}
              textColor="darkCyanLimeGreen"
              bgColor="white"
              size="m"
              borderColor="darkCyanLimeGreen"
            />
          </div>
        </div>
      }

      {orderNumber &&
        <ShareASalePixel orderNumber={orderNumber} orderType={orderType} email={email} sku={sku} price={orderValue}/>
      }
    </div>
  )
}

export default Thanks
