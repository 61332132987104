import React, { useEffect } from 'react'
import './style.scss'
import WebpImage from '../WebpImage'

const Mission = (props) => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.add('dots')
      document.body.classList.remove('white-bg');
    }
  }, [])

  return (
    <div className={`mission container-fluid ${props.bg}-bg-image ${props.bgColor}-bg `}>
      <div className="text-wrapper">
        <h1 className={`title ${props.color}` }>{props.title}</h1>
        {props.subtitle ? (
          <h2 className="title subtitle">{props.subtitle}</h2>
        ) : null}

        <div className="line-through">
          <div className="divider" />
          <div className={`dots-wrapper ${props.bgColor}-bg`}>
            <WebpImage className="hubble-dots" fileName='white-dots.svg' />
          </div>
        </div>

        {props.paragraph ? (
          <h4 className="paragraph work-sans">{props.paragraph}</h4>
        ) : null}
      </div>
    </div>
  )
}

export default Mission
